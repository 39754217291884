<template>
  <div class="row mb-4">
    <select-allegro-account-modal ref="selectAllegroAccountModal" @select="openAdvancedProductDetailsModal" />
    <select-catalog-type-modal ref="selectCatalogTypeModal" @select="openSelectAllegroAccountModal" />

    <div class="col-xl-12">
      <div class="row product-card-body nopadding">
        <div class="col-xl-1 col-md-12">
          <div class="row h-100">
            <div :class="productIds.includes(product().id) ? 'selected' : ''" class="col-xl-4 col-md-12 py-xl-0 py-2 product-card-select" v-if="!productsCatalog">
              <div class="custom-control custom-checkbox">
                <input
                    :id="`check-${product().id}`"
                    :checked="productIds.includes(product().id)"
                    :class="productIds.includes(product().id) ? 'custom-control-label-selected' : ''"
                    :value="product().id"
                    class="custom-control-input"
                    type="checkbox"
                    @change="$parent.$parent.$emit('select-or-unselect-product', product())"
                />
                <label :for="`check-${product().id}`" class="custom-control-label">&nbsp;</label>
              </div>
            </div>
            <div
                class="col-xl-8 col-md-12 py-3 py-xl-0 product-card-image d-flex align-items-center justify-content-center">
              <template v-if="product().images.length > 0">
                <a class="custom-image" @click="$parent.$parent.$emit('open-images-modal', product().images)">
                  <v-lazy-image
                      :src="product().images[0]"
                      alt="Product Image"
                      class="custom-image"
                      src-placeholder="https://i.imgur.com/OI4ACNk.gif"
                  />
                </a>
              </template>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-12 product-card-body">
<!--          <div class="row">-->
<!--            <div-->
<!--                class="col-12 col-xl-9 order-12 order-xl-1 d-flex justify-content-xl-start justify-content-center">-->
<!--              &lt;!&ndash;                              <div class="d-flex flex-column d-xl-inline-flex flex-xl-row vw-100">&ndash;&gt;-->
<!--              &lt;!&ndash;                                <div class="product-info mr-2 info-green mb-2 mb-xl-0">&ndash;&gt;-->
<!--              &lt;!&ndash;                                  Status Oferty: <span class="subtitle">Sukces</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                                <div class="product-info mr-2 info-orange">&ndash;&gt;-->
<!--              &lt;!&ndash;                                  Numer Oferty: <span class="subtitle decoration-underline">15183570662</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div-->
<!--                class="col-12 col-xl-3 order-1 order-xl-12 d-flex justify-content-xl-end justify-content-center pb-2 pb-xl-0">-->
<!--              <div class="d-inline-flex align-items-center">-->
<!--                <div class="product-edit">-->
<!--                  Produkt zmodyfikowany 22.01.2024, 12:57 przez:-->
<!--                </div>-->
<!--                <img alt="Icon" class="ml-2" src="@/assets/icons/web.svg"/>-->
<!--                <img alt="Icon" class="ml-2" src="@/assets/icons/home.svg"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="row my-2 my-xl-0">
            <div class="col-xl product-body-title text-xl-left text-center">
              {{ showFullProductName ? product().name : product().name.slice(0, 100) }} <span v-if="product().name.length > 100" @click="showFullProductName = !showFullProductName" class="product-more-info">({{ showFullProductName ? "Ukryj pełną nazwę produktu" : "Pełna nazwa produktu" }})</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xl product-send-target text-center text-xl-left">
              Wysłano do:

              <template v-if="product().sendTargetList && product().sendTargetList.length > 0">
                <span v-for="(target, index) in product().sendTargetList" :key="index">
                  <span class="subtitle">{{ correctMarketplaceName(target) }}</span>{{ index === product().sendTargetList.length - 1 ? '.' : ',' }}
                </span>
              </template>
              <template v-else>
                <span class="subtitle">Brak</span>
              </template>

<!--              <span class="badge badge-shop-type mr-1 w-sm" v-if="product().sendTargetList && product().sendTargetList.includes('ALLEGRO')">&ndash;&gt;-->
                <!--                    <img src="@/assets/images/brands/allegro.svg" class="w-75 my-1" alt="Allegro">-->
                <!--                  </span>-->
                <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('MAGENTO')">-->
                <!--                    <img src="@/assets/images/brands/aderlo.svg" class="w-75 my-1" alt="Aderlo">-->
                <!--                  </span>-->
                <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WIEJSKI_TARG')">-->
                <!--                    <img src="@/assets/images/brands/wiejski-targ.svg" class="w-75 my-1" alt="Wiejski Targ">-->
                <!--                  </span>-->
                <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WORDPRESS')">-->
                <!--                    <img src="@/assets/images/brands/wordpress.svg" class="w-75 my-1" alt="WordPress">-->
                <!--                  </span>-->
                <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else>-->
                <!--                    Brak-->
                <!--                  </span>-->
<!--              <span class="subtitle">Allegro</span>, <span class="subtitle">Aderlo</span>,-->
<!--              <span class="subtitle">Wiejski Targ</span>-->
            </div>
          </div>

          <!--                          <div class="row">-->
          <!--                            <div class="col-xl product-send-target text-center text-xl-left">-->
          <!--                              Produkt dozwolony do wysyłki na: <span class="subtitle">Allegro</span>, <span class="subtitle">Aderlo</span>,-->
          <!--                              <span class="subtitle">Wiejski Targ</span>-->
          <!--                            </div>-->
          <!--                          </div>-->
          <!--                          <div class="row nopadding py-2">-->
          <!--                            <div class="col-xl-3 col-6 product-detail-item">-->
          <!--                              Data próby wysłania: <span class="subtitle">15.04.2024</span>-->
          <!--                            </div>-->
          <!--                            <div class="col-xl-3 col-6 product-detail-item">-->
          <!--                              Data wysłania: <span class="subtitle">15.04.2024</span>-->
          <!--                            </div>-->
          <!--                            <div class="col-xl-3 col-6 product-detail-item">-->
          <!--                              Data aktualizacji oferty: <span class="subtitle">15.04.2024</span>-->
          <!--                            </div>-->
          <!--                            <div class="col-xl-3 col-6 product-detail-item">-->
          <!--                              Data zakończenia oferty: <span class="subtitle">15.04.2024</span>-->
          <!--                            </div>-->
          <!--                          </div>-->
          <div class="nopadding overflow-auto d-flex flex-row py-2">
            <div class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card :description="product().variationsCount > 1 ? 'z wariantami' : 'bez wariantów'" title="Typ produktu:"/>
              <product-detail-card title="SKU:" :description="product().sku.toString()" sort-by-field="sku" :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>
              <product-detail-card title="EAN:"><render-product-eans :product="product()" :selected-ean="form.selectedEan" /></product-detail-card>
              <product-detail-card
                  title="Dozwolony do sprzedaży na Allegro:"
                  :badge="true"
                  :badge-class="productHelper.getAllegroSendStatus(product()) ? 'badge-soft-success' : 'badge-soft-danger'"
                  :badge-text="$t(productHelper.getAllegroSendStatus(product()) ? 'message.yes' : 'message.no')"
              />
              <product-detail-card title="Stawka podatku:" :description="`${product().tax.rate.toString()}%`" sort-by-field="taxId" :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>

              <!--              <product-detail-card-->
<!--                  title="Ilość sztuk:"-->
<!--                  :description="Number(product().totalWarehouseStock + product().totalDepotStock - product().amountReserved).toString()"-->
<!--                  sort-by-field="totalWarehouseStock" :sortable-by="sortableBy"-->
<!--                  :sortable-by-field="sortableByField"-->
<!--              />-->

            </div>
            <div class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card
                  title="Narzut:"
                  :description="productsHelper.profitMarginFormatter(null, null, product())"
              />

<!--              <product-detail-card title="Zysk Sklep:" :description="productsHelper.profitFormatter(null, null, product(), taxRate())"/>-->

              <product-detail-card
                  title="Zysk ECAT eCommerce (Full Dropshipping):"
                  :description="productsHelper.ecatProfitFormatter(null, null, product(), product().tax.rate)"
              />

              <product-detail-card
                  title="Koszty logistyki i księgowości:"
                  :description="productsHelper.logisticsFormatter(null, null, product(), product().tax.rate)"
              />

              <product-detail-card
                  title="Cena sklepu netto:"
                  :description="productsHelper.priceLowFormatter(null, null, product(), product().tax.rate)"
                  sort-by-field="priceLow"
                  :sortable-by="sortableBy"
                  :sortable-by-field="sortableByField"
              />

              <product-detail-card
                  title="Cena hurtowni netto:"
                  :description="productsHelper.parsePrice(product().priceWholesaleHigh)"
                  sort-by-field="priceWholesaleHigh" :sortable-by="sortableBy"
                  :sortable-by-field="sortableByField"
              />
<!--              <product-detail-card description="38%" title="Zwiększono zysk o kwotę:"/>-->
<!--              <product-detail-card description="20%" title="Zwiększono zysk o procent:"/>-->
<!--              <product-detail-card description="10%" title="Zmniejszono zysk o procent:"/>-->
<!--              <product-detail-card description="0%" title="Dolicznona prowizja allegro:"/>-->
<!--              <product-detail-card description="Nie" title="Produkt z płatnej kategorii:"/>-->
            </div>
            <div class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card :description="category() ? category().name : '-'" title="Kategoria ECAT:"/>
              <!-- <product-detail-card title="Kategoria XML:" description="Kategoria nieprzyporządkowana"/>-->
              <product-detail-card title="Branża:" :description="industry() ? industry().name : '-'" />
              <product-detail-card :description="deliveryTime()" title="Przewidywany czas wysyłki:"/>
<!--              <product-detail-card description="4 dni" title="Przewidywany czas dostawy:"/>-->
<!--              <product-detail-card description="Nie" title="Oznaczony jako gabaryt:"/>-->
              <product-detail-card :description="shippingCostsText()" title="Koszt dostawy:"/>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-12 product-card-actions">
          <button class="btn btn-outline-yellow" @click="$parent.$parent.$emit('open-details-modal', product())">
            Szczegóły
          </button>
          <!--                          <button class="btn btn-green d-inline-block">-->
          <!--                            Wyślij Produkt-->
          <!--                          </button>-->
          <!--                          <button class="btn btn-outline-orange">-->
          <!--                            Zobacz wystawioną ofertę na Allegro-->
          <!--                          </button>-->
          <button class="btn btn-outline-gray" @click="$parent.$parent.$emit('open-price-details-modal', product())">
            Szczegóły Ceny
          </button>

          <button class="btn btn-outline-yellow" @click="$refs.selectCatalogTypeModal.openModal()"> <!-- @click="this.$refs.advancedProductDetailModal.openModal" -->
            Szczegóły produktu Zmodyfikuj z AI
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="row nopadding">
        <div class="col-xl-2 card-option">
          <span>EAN:</span>
          <vue-multiselect
              :value="form.selectedEan"
              @select="changeEan"
              :options="eanList"
              placeholder="Wybierz EAN"
              :show-labels="false">
            <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>
          </vue-multiselect>
          <!--               @select="value => selectVariation(value)" -->
        </div>
        <div class="col-xl-2 card-option">
          <span>SKU:</span>
          <vue-multiselect
              :value="form.selectedSku"
              @select="changeSku"
              :options="skuList"
              placeholder="Wybierz SKU"
              :show-labels="false">
            <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>
          </vue-multiselect>
        </div>
        <div class="col-xl-3 card-option">
          <span>Hurtownia:</span>
          {{ product().warehouse ? product().warehouse.name : '' }}
        </div>
        <div class="col-xl-1 col-6 card-option">
          <span>Ilość sztuk:</span>
          {{ Number(product().totalWarehouseStock + product().totalDepotStock - product().amountReserved).toString() }}
        </div>
        <div class="col-xl-2 col-6 card-option">
          <span>Cena sklepu brutto:</span>
          {{ productsHelper.priceHighFormatter(null, null, product()) }}
        </div>
        <div class="col-xl-2 card-option highlight">
          <span>Twój przewidywany zysk:</span>
          {{ productsHelper.profitFormatter(null, null, product(), product().tax.rate) }}
        </div>
      </div>
    </div>
  </div>
  <!--            <div class="product-header-box">-->
  <!--              <div class="product-header-title font-weight-bold">-->
  <!--                {{ product().name }}-->
  <!--              </div>-->
  <!--              <div class="product-header-subtitle d-flex flex-row align-items-center">-->
  <!--                <div class="font-weight-bold mr-1">-->
  <!--                  Wysłano do:-->
  <!--                </div>-->

  <!--                <div class="product-header-badges">-->
  <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-if="product().sendTargetList && product().sendTargetList.includes('ALLEGRO')">-->
  <!--                    <img src="@/assets/images/brands/allegro.svg" class="w-75 my-1" alt="Allegro">-->
  <!--                  </span>-->
  <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('MAGENTO')">-->
  <!--                    <img src="@/assets/images/brands/aderlo.svg" class="w-75 my-1" alt="Aderlo">-->
  <!--                  </span>-->
  <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WIEJSKI_TARG')">-->
  <!--                    <img src="@/assets/images/brands/wiejski-targ.svg" class="w-75 my-1" alt="Wiejski Targ">-->
  <!--                  </span>-->
  <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WORDPRESS')">-->
  <!--                    <img src="@/assets/images/brands/wordpress.svg" class="w-75 my-1" alt="WordPress">-->
  <!--                  </span>-->
  <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else>-->
  <!--                    Brak-->
  <!--                  </span>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="product-header-details">-->
  <!--          <button class="btn-lg btn-product-details px-4"-->
  <!--                  @click="$parent.$parent.$emit('open-details-modal', product())">-->
  <!--            Szczegóły-->
  <!--          </button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="product-body">-->
  <!--      <div class="col-xl-12 row py-2">-->
  <!--        <div class="col-sm-4">-->

  <!--          <product-detail-card title="SKU:" :description="product().sku.toString()" sort-by-field="sku"-->
  <!--                               :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="EAN:">-->
  <!--            <render-product-eans :product="product()" />-->
  <!--          </product-detail-card>-->
  <!--          <product-detail-card title="Nazwa kategorii:" :description="category() ? category().name : '-'"-->
  <!--                               sort-by-field="categoryId" :sortable-by="sortableBy"-->
  <!--                               :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card-->
  <!--              title="Dozwolony do sprzedaży na Allegro:"-->
  <!--              :badge="true"-->
  <!--              :badge-class="productHelper.getAllegroSendStatus(product()) ? 'badge-soft-success' : 'badge-soft-danger'"-->
  <!--              :badge-text="$t(productHelper.getAllegroSendStatus(product()) ? 'message.yes' : 'message.no')"-->
  <!--          />-->

  <!--          <product-detail-card title="Ilość sztuk:"-->
  <!--                               :description="Number(product().totalWarehouseStock + product().totalDepotStock - product().amountReserved).toString()"-->
  <!--                               sort-by-field="totalWarehouseStock" :sortable-by="sortableBy"-->
  <!--                               :sortable-by-field="sortableByField"/>-->

  <!--          <product-detail-card title="Branża:" :description="industry() ? industry().name : '-'"/>-->
  <!--        </div>-->
  <!--        <div class="col-sm-4">-->

  <!--          <product-detail-card title="Narzut:"-->
  <!--                               :description="productsHelper.profitMarginFormatter(null, null, product())"/>-->
  <!--          <product-detail-card title="Hurtownia:" :description="warehouse() ? warehouse().name : ''"-->
  <!--                               sort-by-field="warehouseId" :sortable-by="sortableBy"-->
  <!--                               :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="Stawka podatku:" :description="`${taxRate().toString()}%`" sort-by-field="taxId"-->
  <!--                               :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>-->

  <!--          <product-detail-card title="Zysk Sklep:"-->
  <!--                               :description="productsHelper.profitFormatter(null, null, product(), taxRate())"/>-->
  <!--          <product-detail-card title="Zysk ECAT eCommerce (Full Dropshipping):"-->
  <!--                               :description="productsHelper.ecatProfitFormatter(null, null, product(), taxRate())"/>-->


  <!--          <product-detail-card title="Koszty logistyki i księgowości:"-->
  <!--                               :description="productsHelper.logisticsFormatter(null, null, product(), taxRate())"/>-->
  <!--        </div>-->
  <!--        <div class="col-sm-4">-->
  <!--          <product-detail-card title="Cena sklepu brutto:"-->
  <!--                               :description="productsHelper.priceHighFormatter(null, null, product())"-->
  <!--                               sort-by-field="priceHigh" :sortable-by="sortableBy"-->
  <!--                               :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="Cena sklepu netto:"-->
  <!--                               :description="productsHelper.priceLowFormatter(null, null, product(), taxRate())"-->
  <!--                               sort-by-field="priceLow" :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="Cena hurtowni netto:"-->
  <!--                               :description="productsHelper.parsePrice(product().priceWholesaleHigh)"-->
  <!--                               sort-by-field="priceWholesaleHigh" :sortable-by="sortableBy"-->
  <!--                               :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="Czas wysyłki:" :description="deliveryTime()" sort-by-field="deliveryTime"-->
  <!--                               :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>-->
  <!--          <product-detail-card title="Koszt dostawy:" :description="shippingCostsText()"/>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

</template>

<script>

import ProductDetailCard from "@/components/product/product-detail-card.vue";
import VLazyImage from "v-lazy-image/v2";
import {dateUtil} from "@/helpers/date-util";
import {productHelper} from "@/helpers/product-helper";
import {productsHelper} from "@/helpers/products-helper";
import {priceHelper} from "@/helpers/price-helper";
import RenderProductEans from "@/components/render-product-eans.vue";
import {correctMarketplaceName} from "@/util/marketplace-util";
import Swal from "sweetalert2";
import selectAllegroAccountModal from "@/components/allegro/select-allegro-account-modal.vue";
import selectCatalogTypeModal from "@/components/catalog/select-catalog-type-modal.vue";
// import RenderProductEans from "@/components/render-product-eans.vue";

export default {
  components: {selectAllegroAccountModal, selectCatalogTypeModal, RenderProductEans, VLazyImage, ProductDetailCard},

  props: {
    index: { // index of current item
      type: Number
    },

    source: {
      type: Object,
      default() {
        return {}
      }
    },

    // product: {
    //   type: Object,
    //   required: true
    // },

    sortableBy: {
      type: Boolean,
      default: false
    },

    sortableByField: {
      type: String,
      default: ""
    },

    productsCatalog: {
      type: Boolean,
      default: false
    },

    productIds: {
      type: Array,
    },

    deliveryUnits: {
      type: Map,
      required: true
    }
  },

  data() {
    return {
      showFullProductName: false,

      eanList: [],
      skuList: [],

      form: {
        selectedEan: "",
        selectedSku: -1,
        product: null
      },

      catalogForm: {
        type: ""
      }
    }
  },

  computed: {
    productsHelper() {
      return productsHelper
    },

    productHelper() {
      return productHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  methods: {
    correctMarketplaceName,
    product() {
      return this.form.product ? this.form.product : this.source
    },

    category() {
      return this.product().category
    },

    // warehouse() {
    //   if (!this.warehouses || !this.product().warehouseId) {
    //     return null
    //   }
    //
    //   for (const warehouse of this.warehouses) {
    //     if (warehouse.id === this.product().warehouseId) {
    //       return warehouse
    //     }
    //   }
    //
    //   return null
    // },

    industry() {
      return this.product().warehouse.industry
    },

    deliveryUnit(deliveryUnit) {
      if (!this.deliveryUnits || !deliveryUnit) {
        return null
      }

      return this.deliveryUnits.get(deliveryUnit) || null
    },

    deliveryTime() {
      if (this.product().deliveryTime && this.product().deliveryUnit) {
        const deliveryUnit = this.deliveryUnit(this.product().deliveryUnit)
        if (deliveryUnit) {
          return this.product().deliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.product().warehouse
      if (warehouse) {
        if (!warehouse.deliveryUnit) {
          return this.$t('products.no-delivery-time')
        }

        const deliveryUnit = this.deliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    shippingCostsText() {
      const shippingCostsForProduct = this.product().shippingCosts
      if (!shippingCostsForProduct) {
        return "-"
      }

      let value = ""
      for (const shippingCost of shippingCostsForProduct) {
        value += `${shippingCost.name}: ${priceHelper.format(shippingCost.cost, this.$store.getters['market/currentCurrency'])} / `
      }

      if (value.endsWith('/ ')) {
        value = value.substring(0, value.length - 3)
      }

      return value
    },

    async changeEan(ean) {
      this.form.selectedEan = ean
      const selectedSku = this.product().variationIdentityList.find(element => element.ean === ean)
      if (selectedSku) {
        this.form.selectedSku = 'W' + selectedSku.sku
      }

      this.changeProduct()
    },

    async changeSku(sku) {
      this.form.selectedSku = sku

      const searchSku = Number(this.form.selectedSku.replace("W", ""))
      const selectedEan = this.product().variationIdentityList.find(element => element.sku === searchSku)
      if (selectedEan) {
        this.form.selectedEan = selectedEan.ean
      }

      this.changeProduct()
    },

    changeProduct() {
      const searchSku = Number(this.form.selectedSku.replace("W", ""))
      const productVariation = this.product().productVariation.find(element => element.sku === searchSku)

      if (productVariation) {
        this.form.product.sku = productVariation.sku
        this.form.product.priceWholesaleHigh = productVariation.wholesalePrice
        this.form.product.priceHigh = productVariation.price
        this.form.product.priceMarginTaxIncluded = productVariation.priceMarginTaxIncluded
      }
    },

    openSelectAllegroAccountModal(catalogType) {
      this.catalogForm.type = catalogType
      this.$refs.selectAllegroAccountModal.openModal()
    },

    async openAdvancedProductDetailsModal(allegroAccountId) {
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", allegroAccountId)

      if (!this.$store.getters['allegro/getCurrentAllegroAccountId']) {
        Swal.fire("", "Aby móc edytować szczegóły produktu należy wybrać konto Allegro!", "error");
        return
      }

      const product = this.product()
      this.$parent.$parent.$emit('open-advanced-product-details-modal', this.catalogForm.type, {
        productId: product.id,
        productEan: product.ean,
        productSku: product.sku,
        warehouseProfitMargin: product.warehouseProfitMargin,
        productProfitMargin: product.profitMargin,
        productImages: JSON.stringify(product.images),
        shippingCosts: product.shippingCosts,
        category: product.category,
        warehouse: product.warehouse,
        tax: product.tax,
        productDeliveryUnit: product.deliveryUnit,
        productDeliveryTime: product.deliveryTime
      })
    }
  },

  mounted() {
    this.form.product = this.source
    this.eanList = this.product().variationIdentityList.map(variation => variation.ean)
    this.skuList = this.product().variationIdentityList.map(variation => 'W' + variation.sku)
    this.form.selectedSku = 'W' + this.product().currentVariationSku

    const searchSku = Number(this.form.selectedSku.replace("W", ""))
    this.form.selectedEan = this.product().variationIdentityList.find(element => element.sku === searchSku).ean
  }
}
</script>