<script>
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import ShopProductLimitModal from "@/components/shop-product-limit-modal.vue";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {routerHelper} from "@/helpers/router-helper";
import PageHeader from "@/components/page-header.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import ProductDetailsModal from "./product-details-modal.vue";
import AddProductsToShopModal from "./add-products-to-shop-modal.vue";
import EcatInput from "@/components/ecat-input.vue";
import VirtualList from 'vue-virtual-scroll-list'
import ProductCard from "@/components/product/product-card.vue";
import ProductPriceDetailModal from "@/components/product/product-price-detail-modal.vue";
import {correctMarketplaceName} from "@/util/marketplace-util";
import AdvancedProductDetailModal from "@/components/product/advanced-product-detail-modal.vue";

export default {

  components: {
    AdvancedProductDetailModal,
    ProductPriceDetailModal,
    EcatInput,
    AddProductsToShopModal,
    ProductDetailsModal,
    Switches,
    Multiselect,
    CustomCardSubtitle,
    PageHeader,
    EcatLightboxGallery,
    ShopProductLimitModal,
    VirtualList
  },

  props: {
    shopId: {
      type: String
    },

    productsCatalog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingPage: true,
      emptyMap: new Map(),

      loading: false,
      submitted: false,
      currentShopId: "",
      productCard: ProductCard,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        lastSortKey: "-1",
      },

      sendTargetList: [],

      products: [],

      form: {
        productIds: [],
      },

      selectedProductImage: {
        visible: false,
        images: []
      },

      activeAccount: null,

      inputPage: '',

      tableColumns: {
        visibleModal: false,

        sku: true,
        image: true,
        name: true,
        categoryName: true,
        ean: true,
        profitMargin: true,
        shopProfit: true,
        ecatProfit: true,
        logistics: true,
        priceHigh: true,
        priceLow: true,
        priceWholesaleHigh: true,
        expect: true,
        totalWarehouseStock: true,
        warehouse: true,
        tax: true,
        deliveryTime: true,
        shippingCost: true,
        industry: true
      },

      filtering: {
        showProductsAllowedOnAllegro: false,
        showProductsWithEAN: false,
        bestSellingProducts: false,
        ean: "",
        sku: "",
        name: "",
        priceMin: "",
        priceMax: "",
        warehouseId: "",
        categoryId: "",
        industryId: "",
        sendTarget: []
      },

      bestSellingProductsIds: [],
      deliveryUnitsMap: new Map(),
      activeShop: null,
      productsCount: 0,

      modals: {
        aiGenerateModal: {
          visible: false,

          form: {
            generateName: false,
            generateDescription: false
          }

        }
      },

      orderByMap: new Map([
        ["sku", "p.sku"],
        ["name", "p.name"],
        ["categoryId", "p.category_id"],
        ["priceHigh", "p.price_high"],
        ["priceLow", "p.price_low"],
        ["priceWholesaleHigh", "p.price_wholesale_high"],
        ["totalWarehouseStock", "p.total_warehouse_stock"],
        ["warehouse", "p.warehouse_id"],
        ["taxId", "p.tax_id"],
        ["deliveryTime", "p.delivery_time"]
      ])
    };
  },

  watch: {
    "table.currentPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          if (this.loadingPage) {
            return
          }

          await this.loadProducts();
        }
      },
    },

    "table.perPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          if (this.loadingPage) {
            return
          }

          await this.loadProducts();
        }
      },
    }
  },

  methods: {

    openProductsWebsite(type) {
      switch (type) {
        case "WEBSITE":
          window.location = this.shopId ? '/dashboard/admin/aderlo/shop/products' : '/dashboard/user/shop/products'
          break;
        case "MARKETPLACE":
          window.location = this.shopId ? '/dashboard/admin/aderlo/shop/products?productsOnMarketPlace=true' : '/dashboard/user/shop/products?productsOnMarketPlace=true'
          break;
      }
    },

    async setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      await this.loadProducts()
    },

    // async loadWarehouses() {
    //   try {
    //     if (this.products.length === 0) {
    //       return;
    //     }
    //
    //     const ids = [];
    //     for (let product of this.products) {
    //       ids.push(product.warehouseId);
    //     }
    //
    //     const json = JSON.stringify({
    //       "ids": ids
    //     });
    //
    //     const {data} = await axios.post(`/warehouse/fetch`, json, {
    //       data: {},
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //       }
    //     });
    //
    //     this.warehouses = data
    //     await this.loadIndustries()
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    // async loadIndustries() {
    //   try {
    //     if (this.warehouses.length === 0) {
    //       return;
    //     }
    //
    //     const ids = [];
    //     for (let warehouse of this.warehouses) {
    //       ids.push(warehouse.industryId);
    //     }
    //
    //     const json = JSON.stringify({
    //       "ids": ids
    //     });
    //
    //     const result = await axios.post(`/industry/fetch`, json, {
    //       data: {},
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //       }
    //     });
    //
    //     this.industries = new Map(result.data.map((obj) => [obj.id, obj]));
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    clearFilters() {
      this.filtering.showProductsAllowedOnAllegro = false
      this.filtering.showProductsWithEAN = false
      this.filtering.bestSellingProducts = false
      this.filtering.sku = ""
      this.filtering.ean = ""
      this.filtering.name = ""
      this.filtering.priceMin = ""
      this.filtering.priceMax = ""
      this.filtering.warehouseId = ""
      this.filtering.categoryId = ""
      this.filtering.industryId = ""
      this.filtering.sendTarget = ""
      this.setToFirstPageAndRefresh()
    },

    resetFilter(filter) {
      this.filtering[filter.field] = filter.isBool ? false : "";
      this.setToFirstPageAndRefresh()
    },

    getFilters() {
      const filters = []
      if (this.filtering.name) {
        filters.push(this.createFilter("Nazwa produktu", "badge-custom-yellow", "name"))
      }

      if (this.filtering.ean) {
        filters.push(this.createFilter("EAN", "badge-custom-purple", "ean"))
      }

      if (this.filtering.sku) {
        filters.push(this.createFilter("SKU", "badge-soft-success", "sku"))
      }

      if (this.filtering.priceMin) {
        filters.push(this.createFilter("Cena minimalna", "badge-custom-light-purple", "priceMin"))
      }

      if (this.filtering.priceMax) {
        filters.push(this.createFilter("Cena maksymalna", "badge-custom-dark-purple", "priceMax"))
      }

      if (this.filtering.industryId) {
        filters.push(this.createFilter("Branża", "badge-custom-deep-teal", "industryId"))
      }

      if (this.filtering.warehouseId) {
        filters.push(this.createFilter("Hurtownia", "badge-soft-info", "warehouseId"))
      }

      if (this.filtering.categoryId) {
        filters.push(this.createFilter("Kategoria", "badge-soft-warning", "categoryId"))
      }

      if (this.filtering.showProductsWithQuantityIsZero) {
        filters.push(this.createFilter("Produkty ze stanem zerowym", "badge-custom-sea", "showProductsWithQuantityIsZero", true))
      }

      if (this.filtering.showProductsWithEAN) {
        filters.push(this.createFilter("Produkty posiadające EAN", "badge-custom-sapphire", "showProductsWithEAN", true))
      }

      if (this.filtering.bestSellingProducts) {
        filters.push(this.createFilter("Produkty najlepiej sprzedające się w ciągu 30 dni", "badge-custom-aqua", "bestSellingProducts", true))
      }

      if (this.filtering.showProductsAllowedOnAllegro) {
        filters.push(this.createFilter("Produkty dozwolone do sprzedaży na Allegro", "badge-custom-aqua", "showProductsAllowedOnAllegro", true))
      }

      if (filters.length === 0) {
        filters.push(this.createFilter("Brak", "badge-soft-danger", null))
      }

      // if (this.filtering.shopCategoryId) {
      //   filters.push(this.createFilter("Kategoria sklepu", "badge-custom-pink", "shopCategoryId"))
      // }

      // if (this.filtering.status) {
      //   filters.push(this.createFilter("Status", "badge-custom-gray", "status"))
      // }

      return filters
    },

    createFilter(name, badge, field, isBool = false) {
      return {
        name: name,
        badge: badge,
        field: field,
        isBool: isBool
      }
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t(this.productsCatalog ? 'products-catalog.title' : 'products.title'),
          active: true
        }
      ]
    },

    async loadProducts() {
      this.loading = true;
      if (this.filtering.bestSellingProducts) {
        await this.loadBestSellingProducts()
      }

      const selectedMarket = this.$store.getters["market/selectedMarket"];
      if (!selectedMarket) {
        return
      }

      try {
        if (this.table.currentPage < 10) {
          this.table.lastSortKey = "-1"
        }

        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.sortBy) {
          const orderByElement = this.orderByMap.get(this.table.sortBy)
          if (orderByElement) {
            orderBy = JSON.stringify({
              [orderByElement]: this.table.sortDesc ? "DESC" : "ASC"
            })
          }
        }

        const {data} = await axios.get(`/product`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: this.table.lastSortKey === "-1" ? page : null,
            searchAfter: this.table.lastSortKey !== "-1" ? this.table.lastSortKey : null,
            size: this.table.perPage,
            shopId: this.getSelectedShopId,
            showProductsAllowedOnAllegro: this.filtering.showProductsAllowedOnAllegro,
            showProductsWithEan: this.filtering.showProductsWithEAN,
            bestSellingProducts: this.filtering.bestSellingProducts,
            filterByProductIds: this.bestSellingProductsIds.join(","),
            sku: this.filtering.sku,
            ean: this.filtering.ean,
            name: this.filtering.name,
            priceMin: this.filtering.priceMin ? this.filtering.priceMin : null,
            priceMax: this.filtering.priceMax ? this.filtering.priceMax : null,
            warehouseId: this.filtering.warehouseId,
            categoryId: this.filtering.categoryId,
            industryId: this.filtering.industryId,
            marketId: selectedMarket.id,
            filter: this.table.filter,
            orderBy: orderBy,
            excludeSendTarget: !this.filtering.sendTarget.allegro ? this.filtering.sendTarget.id : '',
            excludeAllegroAccount: this.filtering.sendTarget.allegro ? this.filtering.sendTarget.id : ''
          }
        });

        const {products, count, lastSortKey} = data
        this.products = products
        this.table.totalRows = count
        this.table.rows = count

        if (this.table.currentPage >= 10) {
          this.table.lastSortKey = lastSortKey
        }

        // await Promise.all([
        //   this.loadWarehouses(),
        // ]);

        this.loading = false
      } catch (error) {
        console.log(error)
        this.products = []
        this.table.totalRows = 0
        this.table.rows = 0
        this.loading = false
      }
    },

    async loadBestSellingProducts() {
      try {
        const {data} = await axios.get(`/product/best-selling`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        await this.loadProductVariationsByIds(data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadProductVariationsByIds(bestSellingProducts) {
      try {
        const ids = [];
        for (const bestSellingProduct of bestSellingProducts) {
          ids.push(bestSellingProduct.productVariationId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const {data} = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        const bestSellingProductsIds = [];
        for (let productVariation of data) {
          bestSellingProductsIds.push(productVariation.productId);
        }

        this.bestSellingProductsIds = bestSellingProductsIds
      } catch (error) {
        console.log(error)
      }
    },

    async openModal() {
      if (this.form.productIds.length === 0) {
        await Swal.fire(this.$t('message.error'), this.$t('products.add.error'), "error");
        return;
      }

      this.$refs.addProductsToShopModal.openModal(this.getSelectedShopId, this.form.productIds)
    },

    selectOrUnSelectProduct(product) {
      if (this.form.productIds.includes(product.id)) {
        this.form.productIds = this.form.productIds.filter(element => element !== product.id)
      } else {
        this.form.productIds.push(product.id)
      }
    },

    clearProductIds() {
      this.form.productIds = []
    },

    getShopName(id) {
      const shop = this.getShop(id)
      return shop.name || ""
    },

    getShop(id) {
      const shops = this.$store.getters['shop/getShops']
      if (!shops) {
        return
      }

      for (const shop of shops) {
        if (shop.id === id) {
          return shop
        }
      }

      return {}
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    selectAllProducts() {
      const ids = this.products.map(product => product.id);
      const newProductIds = ids.filter(id => !this.form.productIds.includes(id));

      if (newProductIds.length > 0) {
        this.form.productIds.push(...newProductIds);
      } else {
        this.form.productIds = this.form.productIds.filter(id => !ids.includes(id))
      }
    },

    isSameProductIds() {
      if (this.products.length === 0) {
        return false
      }

      const ids = this.products.map(product => product.id);
      return ids.every(id => this.form.productIds.includes(id))
    },

    async loadDeliveryUnits() {
      try {
        const {data} = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnitsMap = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadActiveShop(shopId) {
      try {
        const {data} = await axios.get(`/shop/${shopId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.activeShop = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAllProductsCount() {
      if (this.productsCatalog) {
        return
      }

      try {
        const {data} = await axios.get(`/shop/${this.getSelectedShopId}/product/count/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.productsCount = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    openGenerateAIModal() {
      if (this.form.productIds.length === 0) {
        Swal.fire(this.$t('message.error'), "Najpierw zaznacz produkty dla których chcesz wygenerować nazwę/opis AI", "error");
        return;
      }

      this.modals.aiGenerateModal.visible = true
    },

    hideGenerateAIModal() {
      this.modals.aiGenerateModal.visible = false
      this.modals.aiGenerateModal.form.generateName = false
      this.modals.aiGenerateModal.form.generateDescription = false
    },

    generateAI() {
      if (this.form.productIds.length === 0) {
        Swal.fire(this.$t('message.error'), "Najpierw zaznacz produkty dla których chcesz wygenerować nazwę/opis AI", "error")
            .then(() => this.hideGenerateAIModal());
        return;
      }

      if (!this.modals.aiGenerateModal.form.generateName && !this.modals.aiGenerateModal.form.generateDescription) {
        Swal.fire(this.$t('message.error'), "Musisz zaznaczyć przynajmniej jedną opcję!", "error");
        return
      }

      this.$store.dispatch("aiproductsgenerate/setData", {
        productIds: this.form.productIds,
        generateName: this.modals.aiGenerateModal.form.generateName,
        generateDescription: this.modals.aiGenerateModal.form.generateDescription
      }).then(() => routerHelper.push("/dashboard/ai-products-generate"))
    },

    generateAIModalTotal() {
      if (!this.modals.aiGenerateModal.form.generateName && !this.modals.aiGenerateModal.form.generateDescription) {
        return ""
      }

      let text = "Klikając przycisk wygenerujesz"
      if (this.modals.aiGenerateModal.form.generateName) {
        text += " <b>nazwy</b>"
      }

      if (this.modals.aiGenerateModal.form.generateName && this.modals.aiGenerateModal.form.generateDescription) {
        text += " <b>i opisy</b>"
      } else if (this.modals.aiGenerateModal.form.generateDescription) {
        text += " <b>opisy</b>"
      }

      text += ` dla <b>${this.form.productIds.length} produktów</b>`
      return text
    },

    setCurrentShopId(shopId) {
      this.currentShopId = shopId
    },

    async changeShop(shopId) {
      this.currentShopId = shopId
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", "")
      await this.$store.dispatch("shop/setSelectedShopId", shopId)
      await this.loadActiveShop(shopId)
      await this.loadAllProductsCount()

      if (this.$store.getters['engine/getEngineName'](this.activeShop.engineId) === 'engine.wordpress' && !this.$store.getters['shop/isPairedShop'](this.activeShop.id)) {
        this.$router.push('/dashboard/wordpress-not-activated').then(() => {
          // Swal.fire("", this.$t('user-shops.wordpress-is-not-activated'), "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })
        return
      }

      this.setToFirstPageAndRefresh()
    },

    async changeAllegroAccount(allegroAccountId) {
      await this.$store.dispatch("allegro/setCurrentAllegroAccountId", allegroAccountId)
    },

    selectSendTarget(item) {
      this.filtering.sendTarget = item;
      this.setToFirstPageAndRefresh()
    },

    async loadSendTargetList() {
      try {
        const {data} = await axios.get(`/product/send-target-list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "shopId": this.getSelectedShopId,
          }
        });

        this.sendTargetList = data.sendTargetList.map(sendTarget => {
          return {
            id: sendTarget,
            name: correctMarketplaceName(sendTarget),
            allegro: false
          }
        })

        this.sendTargetList = this.sendTargetList.concat(
            data.allegroAccounts.map(account => {
              return {
                id: account.id,
                name: 'Allegro (' + account.name + ')',
                allegro: true
              }
            })
        )
      } catch (error) {
        this.sendTargetList = []
      }
    },
  },

  computed: {
    Swal() {
      return Swal
    },

    paginationHelper() {
      return paginationHelper
    },

    getSelectedShopId() {
      if (this.currentShopId) {
        return this.currentShopId
      }

      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          if (!this.currentShopId) {
            this.setCurrentShopId(shopId)
          }

          return shopId
        }
      } catch (error) {
        // ignore
      }

      if (this.shopId) {
        return this.shopId
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    }

  },

  async created() {
    try {
      if (!this.productsCatalog) {
        if (!this.getSelectedShopId) {
          return
        }

        await this.loadActiveShop(this.getSelectedShopId)
      }

      await this.loadAllProductsCount()
      await this.loadDeliveryUnits()
      await this.loadSendTargetList()
    } catch (error) {
      console.log(error)
    }

    this.loadingPage = false
  },

  async mounted() {
    this.$root.$on("products-change-sortable", async (field) => {
      this.table.sortBy = field
      this.table.sortDesc = !this.table.sortDesc
      await this.loadProducts()
    });

    setTimeout(async () => await this.loadProducts(), 1250)
  },

  beforeDestroy() {
    this.$root.$off("products-change-sortable");
  }

};
</script>

<template>
  <div>
    <PageHeader :title="$t(productsCatalog ? 'products-catalog.title' : 'products.title')" :items="getItems()"/>

    <div class="row" v-if="activeShop || productsCatalog">
      <div class="col-xl-6 col-12 mb-3" v-if="!productsCatalog">
        <div class="card-body px-0">
          <div class="col-sm-12 shop-header rounded">
            <div class="row">
              <div class="col-xl-1 col-md-12 header-card-icon-bg d-flex align-items-center justify-content-center">
                <img src="@/assets/images/products.svg" alt="Products Icons" class="my-2 my-xl-0">
              </div>

              <div
                  class="col-xl-6 col-md-12 p-3 d-flex d-xl-block align-items-center justify-content-center flex-column">
                <div class="w-100 color-primary text-center text-xl-left">
                  Produkty na sklepie:&nbsp;<span class="text-primary font-weight-bold">
                  {{ activeShop ? activeShop.name : '' }}
                </span>
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-center d-xl-flex flex-xl-row align-items-xl-center w-100">
                  <span class="font-weight-bold font-size-34 color-secondary">{{ productsCount }}/{{
                      activeShop.productLimit
                    }}
                  </span>

                  <b-button variant="primary" class="w-md px-4 mx-2 w-100"
                            @click="$refs.shopProductLimitModal.openModal(activeShop.id)">
                    <i class="mdi mdi-plus mr-2"></i>
                    Zwiększ limit
                  </b-button>
                </div>
              </div>

              <div class="col-xl-5 p-3">
                <div v-if="$store.getters['shop/getShops'] && $store.getters['shop/getShopsIds']">
                  <label>Aktywny sklep</label>
                  <multiselect
                      class="shop-selector"
                      :value="getSelectedShopId"
                      @select="value => changeShop(value)"
                      :custom-label="value => getShopName(value)"
                      :options="$store.getters['shop/getShopsIds']"
                      placeholder="Wybierz aktywny sklep"
                      :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="row nopadding card-buttons">
          <div class="col-xl card-button active">
            <i class="fas fa-boxes mr-2"></i>
            <div class="text">
              Wszystkie dostępne produkty
            </div>
          </div>
          <div class="col-xl card-button" @click="openProductsWebsite('WEBSITE')">
            <i class="fas fa-store-alt mr-2"></i>
            <div class="text">
              Produkty dodane do sklepu internetowego
            </div>
          </div>
          <div class="col-xl card-button" @click="openProductsWebsite('MARKETPLACE')">
            <i class="fas fa-shopping-basket mr-2"></i>
            <div class="text">
              Produkty dodane do Marketplace
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body pt-0">
            <!--            <div class="row" v-if="!productsCatalog">-->
            <!--              <div class="col-lg-12">-->
            <!--                <div class="card-body px-0">-->
            <!--                  <div class="col-sm-12 shop-header rounded">-->
            <!--                    <div class="row">-->
            <!--                      <div class="col-xl-2 header-card-icon-bg d-flex align-items-center justify-content-center">-->
            <!--                        <img src="@/assets/images/brands/allegro.svg" alt="Products Icons" class="w-xl p-3">-->
            <!--                      </div>-->

            <!--                      <div class="col-xl-5 px-3 py-2">-->
            <!--                        <div class="w-100 color-primary">-->
            <!--                          Produkty dodane do :&nbsp;<span-->
            <!--                            class="text-primary font-weight-bold">Nazwa Konta Allegro</span>-->
            <!--                        </div>-->

            <!--                        <div class="d-flex flex-row align-items-center"><span-->
            <!--                            class="font-weight-bold font-size-34 color-secondary">265</span>-->

            <!--                          <span class="ml-2 py-2 font-size-12 badge badge-soft-success">1012 Aktywnych aukcji</span>-->
            <!--                        </div>-->
            <!--                      </div>-->

            <!--                      <div class="col-xl-5 px-3 py-2">-->
            <!--                        <div v-if="true">-->
            <!--                          <label>Marketplace:</label>-->
            <!--                          <multiselect-->
            <!--                              class="shop-selector"-->
            <!--                              :value="getSelectedShopId"-->
            <!--                              @select="value => changeShop(value)"-->
            <!--                              :custom-label="value => getShopName(value)"-->
            <!--                              :options="$store.getters['shop/getShopsIds']"-->
            <!--                              placeholder="Wybierz aktywny sklep"-->
            <!--                              :show-labels="false"-->
            <!--                          />-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="row py-2">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-lg-12 row mb-2">
                <div class="col-12 col-lg-2">
                  <ecat-input
                      id="product-name"
                      label="Nazwa produktu"
                      v-model="filtering.name"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-6 col-lg-2">
                  <ecat-input
                      id="ean"
                      label="EAN"
                      v-model="filtering.ean"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-6 col-lg-2">
                  <ecat-input
                      id="sku"
                      label="SKU"
                      v-model="filtering.sku"
                      type="text"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-6 col-lg-2">
                  <ecat-input
                      id="price-min"
                      label="Cena minimalna"
                      v-model="filtering.priceMin"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-6 col-lg-2">
                  <ecat-input
                      id="price-max"
                      label="Cena maksymalna"
                      v-model="filtering.priceMax"
                      type="number"
                      @should-reload="setToFirstPageAndRefresh"
                  />
                </div>

                <div class="col-12 col-lg-2">
                  <div class="d-flex flex-sm-row mx-2 mt-2">
                    <input
                        @change="setToFirstPageAndRefresh()"
                        v-model="filtering.showProductsWithEAN"
                        type="radio"
                        id="show-all-products"
                        :value="false"
                        name="show-all-products"
                        class="input-radio"
                    />
                    <label for="show-all-products" style="margin-left: 5px;">Pokaż wszystkie produkty</label>
                  </div>

                  <div class="d-flex flex-sm-row mx-2 mt-2">
                    <input
                        @change="setToFirstPageAndRefresh()"
                        v-model="filtering.showProductsWithEAN"
                        type="radio"
                        id="show-products-with-ean"
                        :value="true"
                        name="show-products-with-ean"
                        class="input-radio"
                    />
                    <label for="show-products-with-ean" style="margin-left: 5px;">Pokaż produkty posiadające EAN</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 row mb-3">
                <div class="col-lg-2 col-12">
                  <label>Ukryj produkty dodane do:</label>

                  <vue-multiselect
                      v-model="filtering.sendTarget"
                      :custom-label="value => value.name"
                      @remove="selectSendTarget([])"
                      @select="value => selectSendTarget(value)"
                      :options="sendTargetList.filter(item => item.id !== 'ALLEGRO')"
                      placeholder="Wybierz marketplace"
                      :show-labels="false"

                  >
                    <template slot="option" slot-scope="{ option }">
                      {{ option.name }}
                    </template>

                  </vue-multiselect>
                </div>

                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="16"
                      :set-value="filtering.industryId"
                      @change="value => filtering.industryId = value"
                      @select="setToFirstPageAndRefresh()"
                      :can-unselect="true"
                      save-id="id"
                      view-id="name"
                      :label="$t('message.industry')"
                      reset-option-name="Wszystkie branże"
                      placeholder="Wszystkie branże"
                      fetch-one-url="/industry"
                      load-url="/industry/list/pagination"
                      query-url="/industry/by-name"
                      list-name="industries"
                      param="name"
                      :custom-params="{withProducts: true}"
                  />
                </div>

                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="14"
                      :set-value="filtering.warehouseId"
                      @change="value => filtering.warehouseId = value"
                      @select="setToFirstPageAndRefresh()"
                      save-id="id"
                      view-id="name"
                      label="Hurtownia"
                      reset-option-name="Wszystkie hurtownie"
                      placeholder="Wszystkie hurtownie"
                      fetch-one-url="/warehouse"
                      load-url="/warehouse/list/pagination"
                      query-url="/warehouse/by-name"
                      param="name"
                      :custom-params="{withProducts: true, industryId: filtering.industryId, marketId: $store.getters['market/getMarketId']}"
                      :can-unselect="true"
                  />
                </div>

                <div class="col-lg-2 col-12">
                  <ecat-multiselect
                      multi-select-id="15"
                      label="Kategoria"
                      :set-value="filtering.categoryId"
                      @change="value => filtering.categoryId = value"
                      @select="setToFirstPageAndRefresh()"
                      view-id="name"
                      save-id="id"
                      :can-unselect="true"
                      reset-option-name="Wszystkie kategorie"
                      placeholder="Wszystkie kategorie"
                      fetch-one-url="/category"
                      load-url="/category/list/pagination"
                      query-url="/category/by-name"
                      param="name"
                      :custom-params="{withProducts: true}"
                  />
                </div>
              </div>

              <div class="col-lg-12 row mb-3">
                <div class="col-lg-2">
                  <div class="d-flex flex-sm-column">
                    <label class="font-size-13">
                      Pokaż produkty najlepiej sprzedające się w ciągu 30 dni:
                      {{ filtering.bestSellingProducts ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh"
                              v-model="filtering.bestSellingProducts"
                              class="my-0" color="primary"/>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="d-flex flex-sm-column">
                    <label class="font-size-13">
                      Pokaż produkty dozwolone do sprzedaży na Allegro:
                      {{ filtering.showProductsAllowedOnAllegro ? "Tak" : "Nie" }}
                    </label>

                    <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh"
                              v-model="filtering.showProductsAllowedOnAllegro"
                              class="my-0" color="primary"/>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-4 row mt-2 mb-4 pl-4">
                <div class="col-6 pl-0">
                  <b-button variant="primary" class="w-100" @click="loadProducts">Szukaj</b-button>
                </div>
                <div class="col-6 pr-0">
                  <b-button variant="outline-primary" class="w-100" @click="clearFilters">Wyczyść filtry</b-button>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3 text-lg-left text-center">
                <div class="d-flex flex-column ">
                  <div class="font-size-12 font-weight-bold">
                    Aktualne Filtry:
                  </div>
                  <div class="badges">
                    <span v-for="(filter, index) in getFilters()" class="badge font-size-11 mr-1"
                          :class="filter.badge" :key="index">
                      <span v-if="filter.field" @click="resetFilter(filter)" class="clickable-element text-danger">
                        <i class="fa fa-times"/>
                      </span>

                      {{ filter.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row py-2">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-12 col-lg-4 row mt-2 mb-4 pl-4">
                <div class="col-6 pl-0">
                  <b-button variant="success" class="w-100" @click="openModal">+ Dodaj Wybrane produkty do Sklepu
                  </b-button>
                </div>
                <div class="col-6 pr-0">
                  <b-button variant="outline-warning" class="w-100" @click="openGenerateAIModal">Wygeneruj Opisy/Nazwy
                    przez AI
                  </b-button>
                </div>
              </div>
            </div>

            <div class="py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <ecat-form-select id="1" v-model="table.perPage" size="sm"
                                        :options="table.pageOptions"
                                        @loaded="setToFirstPageAndRefresh"></ecat-form-select>&nbsp;{{
                        $t('table.entries')
                      }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                    @keyup.enter="setToFirstPageAndRefresh"
                                    @input="setToFirstPageAndRefresh"></b-form-input>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12 col-md-6">
                  <b-button
                      v-if="!productsCatalog"
                      class="btn-select-all mr-2 d-inline-flex align-items-center justify-content-center py-2 px-3"
                      @click="selectAllProducts">

                    <i class="font-size-20 mr-2" :class="isSameProductIds() ? 'far fa-check-square' : 'far fa-square'"/>
                    <div class="text font-weight-bold">
                      Zaznacz wszystkie
                    </div>
                  </b-button>
                  <b-button variant="primary" class="w-xxl" @click="Swal.fire('Dostępne wkrótce')">
                    Dostosuj dane
                  </b-button>
                </div>

                <div class="col-sm-12 col-md-6 d-inline-flex justify-content-end">

                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"
                                    :hide-goto-end-buttons="true"/>
                    </ul>
                  </div>
                  <!--                  <div class="align-items-center">-->
                  <!--                    <label class="d-inline-flex align-items-center mx-2 mb-0">-->
                  <!--                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>-->
                  <!--                    </label>-->
                  <!--                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do-->
                  <!--                      strony-->
                  <!--                    </b-button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <template v-if="loading">
                <div class="text-center">
                  <h5>{{ $t('message.loading') }}</h5>
                  <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
                </div>
              </template>
              <template v-else>
                <template v-if="!products || products.length === 0">
                  <div class="col-lg-12 text-center product-body py-3 mb-2">
                    Brak danych
                  </div>
                </template>
                <template v-else>
                  <div class="row my-3">
                    <virtual-list
                        :keeps="10"
                        :estimate-size="15"
                        :page-mode="true"
                        style="width: 100%"
                        data-key="id"
                        :data-sources="products"
                        :data-component="productCard"
                        :extra-props="{
                          'products-catalog': productsCatalog,
                          'productIds': form.productIds,
                          'delivery-units': deliveryUnitsMap,
                          'sortable-by': table.sortDesc,
                          'sortable-by-field': table.sortBy
                        }"
                        @open-details-modal="$refs.productDetailsModal.openModal"
                        @select-or-unselect-product="selectOrUnSelectProduct"
                        @open-images-modal="selectProductImages"
                        @open-price-details-modal="$refs.productPriceDetailsModal.openModal"
                        @open-advanced-product-details-modal="$refs.advancedProductDetailModal.openModal"
                    />
                  </div>
                </template>
              </template>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{
                        $t('table.entries-footer', {
                          'amount': paginationHelper.getElementsAmount(table),
                          'elements': paginationHelper.getElementsCount(table),
                          'all': table.totalRows
                        })
                      }}</p>
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"
                                    :hide-goto-end-buttons="true"/>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </div>

    <product-price-detail-modal ref="productPriceDetailsModal"/>
    <advanced-product-detail-modal
        :shop-id="getSelectedShopId"
        :categories="emptyMap"
        :warehouses="emptyMap"
        :taxes="emptyMap"
        :delivery-units="emptyMap"
        :shipping-costs="emptyMap"
        ref="advancedProductDetailModal"
    />

    <product-details-modal ref="productDetailsModal"/>
    <shop-product-limit-modal ref="shopProductLimitModal"/>
    <add-products-to-shop-modal
        ref="addProductsToShopModal"
        @open-shop-product-limit-modal="value => $refs.shopProductLimitModal.openModal(value)"
        @clear-product-ids="clearProductIds"
        @load-products-count="loadAllProductsCount"
    />

    <ecat-lightbox-gallery
        :visible="selectedProductImage.visible"
        :images="selectedProductImage.images"
        @hide="hideSelectedProductImage"
    />

    <b-modal
        id="modal-3"
        v-model="tableColumns.visibleModal"
        title="Wybierz kolumny do pokazania/ukrycia"
        title-class="font-18"
        hide-footer
        @hide="tableColumns.visibleModal = false"
        @esc="tableColumns.visibleModal = false">
      <form>
        <div class="form-group">
          <label class="mx-2"><input v-model="tableColumns.sku" type="checkbox"/> SKU</label>
          <label class="mx-2"><input v-model="tableColumns.image" type="checkbox"/> Zdjęcie</label>
          <label class="mx-2"><input v-model="tableColumns.name" type="checkbox"/> Nazwa produktu</label>
          <label class="mx-2"><input v-model="tableColumns.categoryName" type="checkbox"/> Nazwa kategorii</label>
          <label class="mx-2"><input v-model="tableColumns.ean" type="checkbox"/> EAN</label>
          <label class="mx-2"><input v-model="tableColumns.profitMargin" type="checkbox"/> Narzut</label>
          <label class="mx-2"><input v-model="tableColumns.shopProfit" type="checkbox"/> Zysk sklep</label>
          <label class="mx-2"><input v-model="tableColumns.logistics" type="checkbox"/> Koszty logistyki i
            księgowości</label>
          <label class="mx-2"><input v-model="tableColumns.ecatProfit" type="checkbox"/> Zysk ECAT eCommerce (Full
            Dropshipping)</label>
          <label class="mx-2"><input v-model="tableColumns.priceHigh" type="checkbox"/> Cena sklepu brutto</label>
          <label class="mx-2"><input v-model="tableColumns.priceLow" type="checkbox"/> Cena sklepu netto</label>
          <label class="mx-2"><input v-model="tableColumns.priceWholesaleHigh" type="checkbox"/> Cena hurtowni
            netto</label>
          <label class="mx-2"><input v-model="tableColumns.expect" type="checkbox"/> Dozwolony do sprzedaży na
            Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.totalWarehouseStock" type="checkbox"/> Ilość sztuk</label>
          <label class="mx-2"><input v-model="tableColumns.warehouse" type="checkbox"/> Hurtownia</label>
          <label class="mx-2"><input v-model="tableColumns.tax" type="checkbox"/> Stawka podatku</label>
          <label class="mx-2"><input v-model="tableColumns.deliveryTime" type="checkbox"/> Czas dostawy</label>
          <label class="mx-2"><input v-model="tableColumns.industry" type="checkbox"/> Branża</label>
          <label class="mx-2"><input v-model="tableColumns.shippingCost" type="checkbox"/> Koszt dostawy</label>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="modals.aiGenerateModal.visible"
        title="Generowanie Nazw/Opisów AI"
        title-class="font-18"
        hide-footer
        @hide="hideGenerateAIModal"
        @esc="hideGenerateAIModal">
      <form>
        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="generate-name" v-model="modals.aiGenerateModal.form.generateName" type="checkbox"
                   class="custom-control-input"/>
            <label for="generate-name" class="custom-control-label">Wygeneruj nazwę</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="generate-description" v-model="modals.aiGenerateModal.form.generateDescription" type="checkbox"
                   class="custom-control-input"/>
            <label for="generate-description" class="custom-control-label">Wygeneruj opis</label>
          </div>
        </div>

        <div class="mb-2">
          <p v-html="generateAIModalTotal()"/>
        </div>

        <div class="text-right">
          <b-button @click="generateAI" variant="success">Generuj</b-button>
          <b-button class="ml-1" variant="danger" @click="hideGenerateAIModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </div>
</template>